import React from "react";
import { main, Layout } from "helpers/MainLayout";

const Page404: React.FC = () => (
  <Layout>
    <div>
      <h1>404 - Oh no&apos;s! We couldn&apos;t find that page :(</h1>
    </div>
  </Layout>
);

export default main(Page404);
